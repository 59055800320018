import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Impressum = () => {
  return (
    <Layout>
      <main className="flex-1 w-full max-w-4xl px-4 py-8 mx-auto md:px-8 md:py-16">
        <SEO keywords={[`impressum`, `legal-notice`]} title="Legal Notice" />
        <h1>Privacy Policy</h1>
        <h2>1. An overview of data protection</h2>
        <h3>General information</h3>{" "}
        <p>
          The following information will provide you with an easy to navigate
          overview of what will happen with your personal data when you visit
          this website. The term &ldquo;personal data&rdquo; comprises all data
          that can be used to personally identify you. For detailed information
          about the subject matter of data protection, please consult our Data
          Protection Declaration, which we have included beneath this copy.
        </p>
        <h3>Data recording on this website</h3>{" "}
        <p>
          <strong>
            Who is the responsible party for the recording of data on this
            website (i.e. the &ldquo;controller&rdquo;)?
          </strong>
        </p>{" "}
        <p>
          The data on this website is processed by the operator of the website,
          whose contact information is available under section
          &ldquo;Information Required by Law&rdquo; on this website.
        </p>{" "}
        <p>
          <strong>How do we record your data?</strong>
        </p>{" "}
        <p>
          We collect your data as a result of your sharing of your data with us.
          This may, for instance be information you enter into our contact form.
        </p>{" "}
        <p>
          Other data shall be recorded by our IT systems automatically or after
          you consent to its recording during your website visit. This data
          comprises primarily technical information (e.g. web browser, operating
          system or time the site was accessed). This information is recorded
          automatically when you access this website.
        </p>{" "}
        <p>
          <strong>What are the purposes we use your data for?</strong>
        </p>{" "}
        <p>
          A portion of the information is generated to guarantee the error free
          provision of the website. Other data may be used to analyze your user
          patterns.
        </p>{" "}
        <p>
          <strong>
            What rights do you have as far as your information is concerned?
          </strong>
        </p>{" "}
        <p>
          You have the right to receive information about the source, recipients
          and purposes of your archived personal data at any time without having
          to pay a fee for such disclosures. You also have the right to demand
          that your data are rectified or eradicated. If you have consented to
          data processing, you have the option to revoke this consent at any
          time, which shall affect all future data processing. Moreover, you
          have the right to demand that the processing of your data be
          restricted under certain circumstances. Furthermore, you have the
          right to log a complaint with the competent supervising agency.
        </p>{" "}
        <p>
          Please do not hesitate to contact us at any time under the address
          disclosed in section &ldquo;Information Required by Law&rdquo; on this
          website if you have questions about this or any other data protection
          related issues.
        </p>
        <h3>Analysis tools and tools provided by third parties</h3>{" "}
        <p>
          There is a possibility that your browsing patterns will be
          statistically analyzed when your visit this website. Such analyses are
          performed primarily with what we refer to as analysis programs.
        </p>{" "}
        <p>
          For detailed information about these analysis programs please consult
          our Data Protection Declaration below.
        </p>
        <h2>2. Hosting and Content Delivery Networks (CDN)</h2>
        <h3>External Hosting</h3>{" "}
        <p>
          This website is hosted by an external service provider (host).
          Personal data collected on this website are stored on the servers of
          the host. These may include, but are not limited to, IP addresses,
          contact requests, metadata and communications, contract information,
          contact information, names, web page access, and other data generated
          through a web site.
        </p>{" "}
        <p>
          The host is used for the purpose of fulfilling the contract with our
          potential and existing customers (Art. 6 para. 1 lit. b GDPR) and in
          the interest of secure, fast and efficient provision of our online
          services by a professional provider (Art. 6 para. 1 lit. f GDPR).
        </p>{" "}
        <p>
          Our host will only process your data to the extent necessary to fulfil
          its performance obligations and to follow our instructions with
          respect to such data.
        </p>
        {/* <h3>Cloudflare</h3>{" "}
        <p>
          We use the &ldquo;Cloudflare&rdquo; service provided by Cloudflare
          Inc., 101 Townsend St., San Francisco, CA 94107, USA. (hereinafter
          referred to as &ldquo;Cloudflare&rdquo;).
        </p>{" "}
        <p>
          Cloudflare offers a content delivery network with DNS that is
          available worldwide. As a result, the information transfer that occurs
          between your browser and our website is technically routed via
          Cloudflare&rsquo;s network. This enables Cloudflare to analyze data
          transactions between your browser and our website and to work as a
          filter between our servers and potentially malicious data traffic from
          the Internet. In this context, Cloudflare may also use cookies or
          other technologies deployed to recognize Internet users, which shall,
          however, only be used for the herein described purpose.
        </p>{" "}
        <p>
          The use of Cloudflare is based on our legitimate interest in a
          provision of our website offerings that is as error free and secure as
          possible (Art. 6 Sect. 1 lit. f GDPR).
        </p>{" "}
        <p>
          For more information on Cloudflare&rsquo;s security precautions and
          data privacy policies, please follow this link:{" "}
          <a
            href="https://www.cloudflare.com/privacypolicy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.cloudflare.com/privacypolicy/
          </a>
          .
        </p> */}
        <h2>3. General information and mandatory information</h2>
        <h3>Data protection</h3>{" "}
        <p>
          The operators of this website and its pages take the protection of
          your personal data very seriously. Hence, we handle your personal data
          as confidential information and in compliance with the statutory data
          protection regulations and this Data Protection Declaration.
        </p>{" "}
        <p>
          Whenever you use this website, a variety of personal information will
          be collected. Personal data comprises data that can be used to
          personally identify you. This Data Protection Declaration explains
          which data we collect as well as the purposes we use this data for. It
          also explains how, and for which purpose the information is collected.
        </p>{" "}
        <p>
          We herewith advise you that the transmission of data via the Internet
          (i.e. through e-mail communications) may be prone to security gaps. It
          is not possible to completely protect data against third party access.
        </p>
        <h3>
          Information about the responsible party (referred to as the
          &ldquo;controller&rdquo; in the GDPR)
        </h3>{" "}
        <p className="mb-4">
          The data processing controller on this website is:
        </p>{" "}
        <p className="mb-4">
          Adam Napso
          <br />
          Fallmerayerstrasse 25 <br />
          80798 <br />
          Muenchen
        </p>
        <p>E-mail: admin@firmap.de</p>
        <p>
          The controller is the natural person or legal entity that
          single-handedly or jointly with others makes decisions as to the
          purposes of and resources for the processing of personal data (e.g.
          names, e-mail addresses, etc.).
        </p>
        <h3>Information on data transfer to the USA</h3>{" "}
        <p>
          Our website uses, in particular, tools from companies based in the
          USA. When these tools are active, your personal information may be
          transferred to the US servers of these companies. We must point out
          that the USA is not a safe third country within the meaning of EU data
          protection law. US companies are required to release personal data to
          security authorities without you as the data subject being able to
          take legal action against this. The possibility cannot therefore be
          excluded that US authorities (e.g. secret services) may process,
          evaluate and permanently store your data on US servers for monitoring
          purposes.&nbsp; We have no influence over these processing activities.
        </p>
        <h3>Revocation of your consent to the processing of data</h3>{" "}
        <p>
          A wide range of data processing transactions are possible only subject
          to your express consent. You can also revoke at any time any consent
          you have already given us. This shall be without prejudice to the
          lawfulness of any data collection that occurred prior to your
          revocation.
        </p>
        <h3>
          Right to object to the collection of data in special cases; right to
          object to direct advertising (Art. 21 GDPR)
        </h3>{" "}
        <p>
          IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6 SECT. 1
          LIT. E OR F GDPR, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE
          PROCESSING OF YOUR PERSONAL DATA BASED ON GROUNDS ARISING FROM YOUR
          UNIQUE SITUATION. THIS ALSO APPLIES TO ANY PROFILING BASED ON THESE
          PROVISIONS. TO DETERMINE THE LEGAL BASIS, ON WHICH ANY PROCESSING OF
          DATA IS BASED, PLEASE CONSULT THIS DATA PROTECTION DECLARATION. IF YOU
          LOG AN OBJECTION, WE WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL
          DATA, UNLESS WE ARE IN A POSITION TO PRESENT COMPELLING PROTECTION
          WORTHY GROUNDS FOR THE PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR
          INTERESTS, RIGHTS AND FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS
          THE CLAIMING, EXERCISING OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION
          PURSUANT TO ART. 21 SECT. 1 GDPR).
        </p>{" "}
        <p>
          IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT
          ADVERTISING, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE
          PROCESSING OF YOUR AFFECTED PERSONAL DATA FOR THE PURPOSES OF SUCH
          ADVERTISING. THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS
          AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL
          DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR DIRECT ADVERTISING
          PURPOSES (OBJECTION PURSUANT TO ART. 21 SECT. 2 GDPR).
        </p>
        <h3>Right to log a complaint with the competent supervisory agency</h3>{" "}
        <p>
          In the event of violations of the GDPR, data subjects are entitled to
          log a complaint with a supervisory agency, in particular in the member
          state where they usually maintain their domicile, place of work or at
          the place where the alleged violation occurred. The right to log a
          complaint is in effect regardless of any other administrative or court
          proceedings available as legal recourses.
        </p>
        <h3>Right to data portability</h3>{" "}
        <p>
          You have the right to demand that we hand over any data we
          automatically process on the basis of your consent or in order to
          fulfil a contract be handed over to you or a third party in a commonly
          used, machine readable format. If you should demand the direct
          transfer of the data to another controller, this will be done only if
          it is technically feasible.
        </p>
        <h3>SSL and/or TLS encryption</h3>{" "}
        <p>
          For security reasons and to protect the transmission of confidential
          content, such as purchase orders or inquiries you submit to us as the
          website operator, this website uses either an SSL or a TLS encryption
          program. You can recognize an encrypted connection by checking whether
          the address line of the browser switches from &ldquo;http://&rdquo; to
          &ldquo;https://&rdquo; and also by the appearance of the lock icon in
          the browser line.
        </p>{" "}
        <p>
          If the SSL or TLS encryption is activated, data you transmit to us
          cannot be read by third parties.
        </p>
        <h3>Information about, rectification and eradication of data</h3>{" "}
        <p>
          Within the scope of the applicable statutory provisions, you have the
          right to at any time demand information about your archived personal
          data, their source and recipients as well as the purpose of the
          processing of your data. You may also have a right to have your data
          rectified or eradicated. If you have questions about this subject
          matter or any other questions about personal data, please do not
          hesitate to contact us at any time at the address provided in section
          &ldquo;Information Required by Law.&rdquo;
        </p>
        <h3>Right to demand processing restrictions</h3>{" "}
        <p>
          You have the right to demand the imposition of restrictions as far as
          the processing of your personal data is concerned. To do so, you may
          contact us at any time at the address provided in section
          &ldquo;Information Required by Law.&rdquo; The right to demand
          restriction of processing applies in the following cases:
        </p>{" "}
        <ul>
          {" "}
          <li>
            In the event that you should dispute the correctness of your data
            archived by us, we will usually need some time to verify this claim.
            During the time that this investigation is ongoing, you have the
            right to demand that we restrict the processing of your personal
            data.
          </li>{" "}
          <li>
            If the processing of your personal data was/is conducted in an
            unlawful manner, you have the option to demand the restriction of
            the processing of your data in lieu of demanding the eradication of
            this data.
          </li>{" "}
          <li>
            If we do not need your personal data any longer and you need it to
            exercise, defend or claim legal entitlements, you have the right to
            demand the restriction of the processing of your personal data
            instead of its eradication.
          </li>{" "}
          <li>
            If you have raised an objection pursuant to Art. 21 Sect. 1 GDPR,
            your rights and our rights will have to be weighed against each
            other. As long as it has not been determined whose interests
            prevail, you have the right to demand a restriction of the
            processing of your personal data.
          </li>{" "}
        </ul>{" "}
        <p>
          If you have restricted the processing of your personal data, these
          data &ndash; with the exception of their archiving &ndash; may be
          processed only subject to your consent or to claim, exercise or defend
          legal entitlements or to protect the rights of other natural persons
          or legal entities or for important public interest reasons cited by
          the European Union or a member state of the EU.
        </p>
        <h2>4. Recording of data on this website</h2>
        <h3>Cookies</h3>{" "}
        <p>
          Our websites and pages use what the industry refers to as
          &ldquo;cookies.&rdquo; Cookies are small text files that do not cause
          any damage to your device. They are either stored temporarily for the
          duration of a session (session cookies) or they are permanently
          archived on your device (permanent cookies). Session cookies are
          automatically deleted once you terminate your visit. Permanent cookies
          remain archived on your device until you actively delete them or they
          are automatically eradicated by your web browser.
        </p>{" "}
        <p>
          In some cases it is possible that third party cookies are stored on
          your device once you enter our site (third party cookies). These
          cookies enable you or us to take advantage of certain services offered
          by the third party (e.g. cookies for the processing of payment
          services).
        </p>{" "}
        <p>
          Cookies have a variety of functions. Many cookies are technically
          essential since certain website functions would not work in the
          absence of the cookies (e.g. the shopping cart function or the display
          of videos). The purpose of other cookies may be the analysis of user
          patterns or the display of promotional messages.
        </p>{" "}
        <p>
          Cookies, which are required for the performance of electronic
          communication transactions (required cookies) or for the provision of
          certain functions you want to use (functional cookies, e.g. for the
          shopping cart function) or those that are necessary for the
          optimization of the website (e.g. cookies that provide measurable
          insights into the web audience), shall be stored on the basis of Art.
          6 Sect. 1 lit. f GDPR, unless a different legal basis is cited. The
          operator of the website has a legitimate interest in the storage of
          cookies to ensure the technically error free and optimized provision
          of the operator&rsquo;s services. If your consent to the storage of
          the cookies has been requested, the respective cookies are stored
          exclusively on the basis of the consent obtained (Art. 6 Sect. 1 lit.
          a GDPR); this consent may be revoked at any time.
        </p>{" "}
        <p>
          You have the option to set up your browser in such a manner that you
          will be notified any time cookies are placed and to permit the
          acceptance of cookies only in specific cases. You may also exclude the
          acceptance of cookies in certain cases or in general or activate the
          delete function for the automatic eradication of cookies when the
          browser closes. If cookies are deactivated, the functions of this
          website may be limited.
        </p>{" "}
        <p>
          In the event that third party cookies are used or if cookies are used
          for analytical purposes, we will separately notify you in conjunction
          with this Data Protection Policy and, if applicable, ask for your
          consent.
        </p>
        <h3>Server log files</h3>{" "}
        <p>
          The provider of this website and its pages automatically collects and
          stores information in so-called server log files, which your browser
          communicates to us automatically. The information comprises:
        </p>{" "}
        <ul>
          {" "}
          <li>The type and version of browser used</li>{" "}
          <li>The used operating system</li> <li>Referrer URL</li>{" "}
          <li>The hostname of the accessing computer</li>{" "}
          <li>The time of the server inquiry</li> <li>The IP address</li>{" "}
        </ul>{" "}
        <p>This data is not merged with other data sources.</p>{" "}
        <p>
          This data is recorded on the basis of Art. 6 Sect. 1 lit. f GDPR. The
          operator of the website has a legitimate interest in the technically
          error free depiction and the optimization of the operator&rsquo;s
          website. In order to achieve this, server log files must be recorded.
        </p>
        <h3>Contact form</h3>{" "}
        <p>
          If you submit inquiries to us via our contact form, the information
          provided in the contact form as well as any contact information
          provided therein will be stored by us in order to handle your inquiry
          and in the event that we have further questions. We will not share
          this information without your consent.
        </p>{" "}
        <p>
          The processing of these data is based on Art. 6 para. 1 lit. b GDPR,
          if your request is related to the execution of a contract or if it is
          necessary to carry out pre-contractual measures. In all other cases
          the processing is based on our legitimate interest in the effective
          processing of the requests addressed to us (Art. 6 Para. 1 lit. f
          GDPR) or on your agreement (Art. 6 Para. 1 lit. a GDPR) if this has
          been requested.
        </p>{" "}
        <p>
          The information you have entered into the contact form shall remain
          with us until you ask us to eradicate the data, revoke your consent to
          the archiving of data or if the purpose for which the information is
          being archived no longer exists (e.g. after we have concluded our
          response to your inquiry). This shall be without prejudice to any
          mandatory legal provisions &ndash; in particular retention periods.
        </p>
        <h3>Request by e-mail, telephone or fax</h3>{" "}
        <p>
          If you contact us by e-mail, telephone or fax, your request, including
          all resulting personal data (name, request) will be stored and
          processed by us for the purpose of processing your request. We do not
          pass these data on without your consent.
        </p>{" "}
        <p>
          These data are processed on the basis of Art. 6 Sect. 1 lit. b GDPR if
          your inquiry is related to the fulfillment of a contract or is
          required for the performance of pre-contractual measures. In all other
          cases, the data are processed on the basis of our legitimate interest
          in the effective handling of inquiries submitted to us (Art. 6 Sect. 1
          lit. f GDPR) or on the basis of your consent (Art. 6 Sect. 1 lit. a
          GDPR) if it has been obtained.
        </p>{" "}
        <p>
          The data sent by you to us via contact requests remain with us until
          you request us to delete, revoke your consent to the storage or the
          purpose for the data storage lapses (e.g. after completion of your
          request). Mandatory statutory provisions - in particular statutory
          retention periods - remain unaffected.
        </p>
        <h3>Mapbox</h3>
        <p>
          Our website uses Mapbox Tiles to display interactive maps on some
          subpages. The Mapbox Tiles API is a map service of Mapbox Inc.
          (Mapbox). By using the Mapbox Tiles API, information about the use of
          this website, including your IP address, may be transmitted to Mapbox
          in the United States. When you visit a page that contains Mapbox Tiles
          maps, your browser establishes a direct connection to the Mapbox
          servers. Mapbox will send the map content directly to your browser and
          integrate it into the website. Therefore, we have no control over the
          amount of data collected by Mapbox in this way. If you do not want
          Mapbox to collect, process or use data about you through our website,
          you can disable JavaScript in your browser settings. However, you will
          not be able to use the map viewer in this case. For more information
          about the purpose and scope of the data collection, as well as the
          further processing and use of the data by Mapbox, your related rights
          and privacy settings, please refer to the Mapbox Privacy Policy at
          https://www.mapbox.com/privacy/ , Among other things, it stores the IP
          address of the visitor, the address of the visited page, the date and
          time, and the pages being accessed. Mapbox claims to use this
          information solely to improve the service provided for diagnostic and
          analytical purposes. Mapbox does not disclose personal data to third
          parties. You also have the option to prevent Mapbox cookies by
          disabling third party cookies in your browser settings.
        </p>
        <h2>5. Analysis tools and advertising</h2>
        <h3>Google Analytics</h3>{" "}
        <p>
          This website uses functions of the web analysis service Google
          Analytics. The provider of this service is Google Ireland Limited
          (&ldquo;Google&rdquo;), Gordon House, Barrow Street, Dublin 4,
          Ireland.
        </p>{" "}
        <p>
          Google Analytics enables the website operator to analyze the behavior
          patterns of website visitors. To that end, the website operator
          receives a variety of user data, such as pages accessed, time spent on
          the page, the utilized operating system and the user&rsquo;s origin.
          Google may consolidate these data in a profile that is allocated to
          the respective user or the user&rsquo;s device.
        </p>{" "}
        <p>
          Google Analytics uses technologies that make the recognition of the
          user for the purpose of analyzing the user behavior patterns (e.g.
          cookies or device fingerprinting). The website use information
          recorded by Google is, as a rule transferred to a Google server in the
          United States, where it is stored.
        </p>{" "}
        <p>
          This analysis tool is used on the basis of Art. 6 Sect. 1 lit. f GDPR.
          The operator of this website has a legitimate interest in the analysis
          of user patterns to optimize both, the services offered online and the
          operator&rsquo;s advertising activities. If a corresponding agreement
          has been requested (e.g. an agreement to the storage of cookies), the
          processing takes place exclusively on the basis of Art. 6 para. 1 lit.
          a GDPR; the agreement can be revoked at any time.
        </p>{" "}
        <h3>IP anonymization</h3>
        <p>
          On this website, we have activated the IP anonymization function. As a
          result, your IP address will be abbreviated by Google within the
          member states of the European Union or in other states that have
          ratified the Convention on the European Economic Area prior to its
          transmission to the United States. The full IP address will be
          transmitted to one of Google&rsquo;s servers in the United States and
          abbreviated there only in exceptional cases. On behalf of the operator
          of this website, Google shall use this information to analyze your use
          of this website to generate reports on website activities and to
          render other services to the operator of this website that are related
          to the use of the website and the Internet. The IP address transmitted
          in conjunction with Google Analytics from your browser shall not be
          merged with other data in Google&rsquo;s possession.
        </p>
        <h3>Browser plug-in</h3>
        <p>
          You can prevent the recording and processing of your data by Google by
          downloading and installing the browser plugin available under the
          following link:{" "}
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://tools.google.com/dlpage/gaoptout?hl=en
          </a>
          .
        </p>{" "}
        <p>
          For more information about the handling of user data by Google
          Analytics, please consult Google&rsquo;s Data Privacy Declaration at:{" "}
          <a
            href="https://support.google.com/analytics/answer/6004245?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/analytics/answer/6004245?hl=en
          </a>
          .
        </p>
        <p>
          <h3>Archiving period</h3>
        </p>{" "}
        <p>
          Data on the user or incident level stored by Google linked to cookies,
          user IDs or advertising IDs (e.g. DoubleClick cookies, Android
          advertising ID) will be anonymized or deleted after 14 month. For
          details please click the following link:{" "}
          <a
            href="https://support.google.com/analytics/answer/7667196?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/analytics/answer/7667196?hl=en
          </a>
        </p>
        <h2>6. Plug-ins and Tools</h2>
        <h3>Google Web Fonts (local embedding)</h3>{" "}
        <p>
          This website uses so-called Web Fonts provided by Google to ensure the
          uniform use of fonts on this site. These Google fonts are locally
          installed so that a connection to Google&rsquo;s servers will not be
          established in conjunction with this application.
        </p>{" "}
        <p>
          For more information on Google Web Fonts, please follow this link:{" "}
          <a
            href="https://developers.google.com/fonts/faq"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.google.com/fonts/faq
          </a>{" "}
          and consult Google&rsquo;s Data Privacy Declaration under:{" "}
          <a
            href="https://policies.google.com/privacy?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=en
          </a>
          .
        </p>
      </main>
    </Layout>
  );
};

export default Impressum;
